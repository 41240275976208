<script>
import { mapState } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { DOMAIN_MAIN } from '@dmant/ez-env-common';

import {
  STEP_TRIGGERS_TABLE,
  TRIGGER_TITLES,
  STEP_ACTIONS_TABLE,
  ACTION_TITLES,
  ACTIONS_WITH_AUTORESPONDER,
  TRIGGERS_WITH_OFFER,
  TRIGGERS_WITH_TIME,
} from '@/enums/actions';

import { NOTIFICATION as emailType } from '@/enums/emailTypes';

import {
  CONTACTS, EMAIL, TYPES_MAP, KLICK_TIPP,
} from '@/enums/actionTypes';

import getProps, * as ACTION_PROPS from '@/enums/actionProps';

import confirmDelete from '@/components/dialogs/confirmDelete';
import EzfSelectWebinarOffer from '@/components/select-webinar-offer.vue';
import EzfSelectMaillist from '@/components/select-maillist.vue';
import EzfSelectAutoresponder from '@/components/select-autoresponder.vue';
import EzfInputMultipleEmails from '@/components/ezf-input-multiple-emails.vue';
import EzfEmailTemplateSelect from '@/components/select-template/email-template-select.vue';
import actionsManager from '@/services/actionsManager';
import usersManager from '@/services/usersManager';
import contactsManager from '@/services/contactsManager';
import { getBuilderUrl } from '@/services/urls';

import NAME from './name.json';

export default {
  name: 'EzfActionDialog',
  inject: ['$validator'],
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    EzfSelectMaillist,
    EzfSelectAutoresponder,
    EzfInputMultipleEmails,
    EzfEmailTemplateSelect,
    EzfSelectWebinarOffer,
  },
  props: {
    stepId: String,
    stepType: Number,
    action: {
      type: Object,
      required: true,
    },
  },
  data() {
    const form = {
      'day-of-cycle': 0,
      ...this.action.attributes,
      tagsToAdd: [],
      tagsToDelete: [],
    };
    form['is-active'] = Boolean(form['is-active']);
    form['is-doi'] = Boolean(form['is-doi']);
    return {
      emailType,
      loading: false,
      form,
      existingTags: [],
      current: {
        tagsToAdd: [],
        tagsToDelete: [],
      },
    };
  },
  computed: {
    ...mapState({ userId: 'currentUserId' }),
    ...mapState('funnel', ['funnel']),
    isEdit() {
      return this.action.id;
    },
    triggers() {
      const triggers = STEP_TRIGGERS_TABLE[this.stepType];
      if (!triggers) {
        throw new Error(
          `Triggers for step type "${this.stepType}" is not defined`,
        );
      }

      return triggers.map((value) => ({
        value,
        title: this.$t(`builder.actions.triggers.${TRIGGER_TITLES[value]}`),
      }));
    },
    actionTypes() {
      const actions = STEP_ACTIONS_TABLE[this.stepType];
      if (!actions) {
        throw new Error(
          `Actions for step type "${this.stepType}" is not defined`,
        );
      }
      return actions.map((value) => {
        const key = ACTION_TITLES[value];
        let title = key;
        if (ACTIONS_WITH_AUTORESPONDER.includes(value)) {
          const autoresponder = this.$t(
            `builder.actions.autoresponders.${key}`,
          );
          title = this.$t('builder.actions.action.autoresponder', {
            autoresponder,
          });
        } else {
          title = this.$t(`builder.actions.action.${key}`);
        }
        return {
          value,
          title,
        };
      });
    },
    withAutoresponder() {
      return ACTIONS_WITH_AUTORESPONDER.includes(this.form['action-type']);
    },
    autoresponderType() {
      return this.withAutoresponder && TYPES_MAP.get(this.form['action-type']);
    },
    actionProps() {
      return getProps(this.form['action-type']);
    },
    withMailList() {
      return this.form['account-id'] && this.actionProps.has(ACTION_PROPS.MAILLISTS);
    },
    withDoiList() {
      return this.form['account-id'] && this.actionProps.has(ACTION_PROPS.DOI_LIST);
    },
    withCycleDay() {
      return this.actionProps.has(ACTION_PROPS.CYCLE_DAY);
    },
    withTagsToAdd() {
      return this.actionProps.has(ACTION_PROPS.TAGS_TO_ADD);
    },
    withTagsToDelete() {
      return this.actionProps.has(ACTION_PROPS.TAGS_TO_DELETE);
    },
    withDoiSwitch() {
      return this.actionProps.has(ACTION_PROPS.DOI_SWITCH);
    },
    withOffer() {
      return TRIGGERS_WITH_OFFER.includes(this.form.trigger);
    },
    withTimeField() {
      return TRIGGERS_WITH_TIME.includes(this.form.trigger);
    },
    isEmailActionType() {
      return EMAIL === this.form['action-type'];
    },
    isKlickTippActionType() {
      return KLICK_TIPP === this.form['action-type'];
    },
    editTemplateHref() {
      return getBuilderUrl(
        this.funnel.id,
        this.form['template-id'],
      );
    },
    klickTippDoiTooltip() {
      if (this.isKlickTippActionType) {
        const confirmationPageUrl = `https://${DOMAIN_MAIN}/rf/${this.funnel.id}/double-opt-in`;
        const subscriptionPageUrl = `https://${DOMAIN_MAIN}/rfnt/${this.funnel.id}`;

        return `<span style="line-height: 1.5em; font-size: 10px">
        ${this.$t('doiprocess.confirmationPageTooltip')}<br>
        <b>${confirmationPageUrl}</b><br>
        <div style="margin-top: 5px;">
        ${this.$t('doiprocess.subscriptionPageTooltip')}<br>
        <b>${subscriptionPageUrl}</b></div>
        </span>`;
      }
      return '';
    },
  },
  methods: {
    close(response) {
      this.$modal.hide(NAME, response);
    },
    addNewTagToAdd(name) {
      const tag = {
        name,
      };
      this.form.tagsToAdd.push(tag);
    },
    selectTagToAdd(tag) {
      const index = this.form.tagsToDelete.indexOf(tag);
      if (index !== -1) {
        this.form.tagsToDelete.splice(index, 1);
      }
    },
    selectTagToDelete(tag) {
      const index = this.form.tagsToAdd.indexOf(tag);
      if (index !== -1) {
        this.form.tagsToAdd.splice(index, 1);
      }
    },
    async remove() {
      const title = this.$t(`builder.actions.triggers.${TRIGGER_TITLES[this.form.trigger]}`);
      const isDelete = await confirmDelete('action', title);
      if (!isDelete) {
        return;
      }
      try {
        this.loading = 'remove';
        await actionsManager.removeAction(this.action.id);
        this.close({
          result: 'removed',
        });
      } catch (e) {
        this.$notify({
          data: {
            type: 'error',
            content: e.message || e,
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async submit() {
      const isValid = await this.$validator.validate('action.*');
      if (!isValid) {
        return;
      }

      const {
        isEdit, form, userId, stepId,
      } = this;
      const { tagsToAdd, tagsToDelete, ...attributes } = form;

      attributes['is-active'] = Number(attributes['is-active']);
      attributes['is-doi'] = Number(this.withDoiSwitch && attributes['is-doi']);
      this.loading = 'submit';
      try {
        let response;
        if (isEdit) {
          response = await actionsManager.updateAction(this.action.id, attributes);
        } else {
          response = await actionsManager.createAction(attributes, {
            userId,
            stepId,
          });
        }
        const { id } = response.data;
        if (this.withTagsToAdd && !isEqual(tagsToAdd, this.current.tagsToAdd)) {
          await actionsManager.bulkCreateTagsToAdd(id, tagsToAdd);
        }
        if (this.withTagsToDelete && !isEqual(tagsToDelete, this.current.tagsToDelete)) {
          await actionsManager.bulkCreateTagsToDelete(id, tagsToDelete);
        }
        const result = isEdit ? 'updated' : 'created';
        this.close({
          result,
          response,
        });

        this.$notify({
          data: {
            type: 'success',
            content: isEdit
              ? this.$t('builder.actions.notify.updated')
              : this.$t('builder.actions.notify.created'),
          },
        });
      } catch (e) {
        this.$notify({
          data: {
            type: 'error',
            content: e.message || e,
          },
        });
        // show /data/attribute/* errors
        const errors = e.response && e.response.data ? e.response.data.errors : [];
        for (const error of errors) {
          const { pointer } = error.source;
          const PREFIX = '/data/attribute/';
          const msg = error.detail || error.title;
          if (pointer.startsWith(PREFIX)) {
            const field = pointer.substr(PREFIX.length);

            this.errors.add({
              field,
              msg,
            });

            this.$notify({
              data: {
                type: 'error',
                content: `${field}: ${msg}`,
              },
            });
          } else {
            this.$notify({
              data: {
                type: 'error',
                content: `${pointer}: ${msg}`,
              },
            });
          }
        }
      } finally {
        this.loading = false;
      }
    },
    updateTagIds() {
      for (const selectedTag of [...this.form.tagsToAdd, ...this.form.tagsToDelete]) {
        const existsTag = this.existingTags.find((tag) => selectedTag.name === tag.name);
        if (existsTag) {
          if (existsTag['tag-id'] !== selectedTag['tag-id']) {
            this.$set(selectedTag, 'tag-id', existsTag['tag-id']);
          }
        } else {
          this.$delete(selectedTag, 'tag-id');
          this.existingTags.push(selectedTag);
        }
      }
    },
    async awaitTags(key, promise) {
      const response = await promise;
      if (response) {
        const tags = response.data.map((r) => r.attributes);
        this.$set(this.current, key, tags);
        this.$set(this.form, key, cloneDeep(tags));
      }
    },
    async loadExistsTags() {
      this.loading = 'tags';
      try {
        await Promise.all([
          this.withTagsToAdd && this.awaitTags('tagsToAdd', actionsManager.getTagsToAdd(this.action.id)),
          this.withTagsToDelete && this.awaitTags('tagsToDelete', actionsManager.getTagsToDelete(this.action.id)),
        ]);
      } catch (e) {
        this.$notify({
          data: {
            type: 'error',
            content: e.message || e,
          },
        });
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    if (this.isEdit) {
      if (this.withTagsToAdd || this.withTagsToDelete) {
        this.loadExistsTags();
      }
    } else {
      if (!this.form.trigger && this.triggers[0]) {
        this.form.trigger = this.triggers[0].value;
      }
      if (!this.form['action-type'] && this.actionTypes[0]) {
        this.$set(this.form, 'account-type', this.actionTypes[0].value);
      }
    }
    // withAutoresponder
    this.$watch(
      () => this.withAutoresponder,
      async (withAutoresponder) => {
        if (!withAutoresponder) {
          this.$set(this.form, 'account-id', null);
          this.$set(this.form, 'list-id', null);
        }
      },
      { immediate: true },
    );
    // action-type
    this.$watch(
      () => this.form['action-type'],
      async (actionType) => {
        if (actionType !== this.action.attributes['action-type'] || this.form['account-id'] !== this.action.attributes['account-id']) {
          this.$set(this.form, 'account-id', null);
          this.$set(this.form, 'list-id', null);
        }
        if (CONTACTS === actionType) {
          const result = await contactsManager.getTags(this.userId);
          this.existingTags = result.data.map(({ id, attributes: { name } }) => ({
            name,
            'tag-id': id,
          }));
          this.updateTagIds();
        }
      },
      { immediate: true },
    );
    this.$watch(
      () => this.form['account-id'],
      async (accountId) => {
        if (accountId !== this.action.attributes['account-id']) {
          this.$set(this.form, 'list-id', null);
        }
        this.existingTags = [];
        if (accountId && (this.withTagsToAdd || this.withTagsToDelete)) {
          const result = await usersManager.getMailListTags(accountId);
          this.existingTags = result.data.tags.map(({ id, value }) => ({
            name: value,
            'tag-id': id,
          }));
        }
        this.updateTagIds();
      },
      { immediate: true },
    );
    this.$watch(
      () => this.withDoiSwitch,
      async (withDoiSwitch) => {
        if (withDoiSwitch && !this.isEdit) {
          this.form['is-doi'] = true;
        }
      },
      { immediate: true },
    );
  },
};
</script>

<template>
  <ez-form ref="form" @submit.prevent="submit" data-test="action-form">
    <ez-dialog-header icon="comments">
      {{
        isEdit
          ? $t("builder.actions.editTitle")
          : $t("builder.actions.createTitle")
      }}
    </ez-dialog-header>
    <ez-dialog-body @close="close()">
      <ez-form-group>
        <div class="panel-collapse">
          <div class="panel-collapse__content">
            <ez-row align="center">
              <ez-col>
                <div class="panel-collapse__info">
                  {{ $t("tags.activeSwitch") }}
                </div>
              </ez-col>
              <ez-col size="7">
                <div class="panel-collapse__switch">
                  <ez-switch
                    v-model="form['is-active']"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                  ></ez-switch>
                </div>
              </ez-col>
            </ez-row>
          </div>
        </div>
      </ez-form-group>
      <ez-form-group>
        <ez-form-item
          :label="$t('builder.actions.trigger.label')"
          :type="errors.has('trigger', 'action') ? 'error' : null"
          :message="errors.first('trigger', 'action')"
        >
          <ez-select
            name="trigger"
            v-model="form.trigger"
            v-validate="'required'"
            data-vv-scope="action"
            :data-vv-as="$t('builder.actions.trigger.label')"
            :placeholder="$t('builder.actions.trigger.placeholder')"
            :options="triggers"
            track-by="value"
            item-label="title"
            :allow-empty="false"
            :searchable="false"
            is-single
          />
        </ez-form-item>
      </ez-form-group>

      <ez-form-group v-if="withTimeField">
        <ez-row>
          <ez-col size="5">
            <ez-form-item
              :label="$t('builder.actions.time.label')"
              :type="errors.has('time', 'action') ? 'error' : null"
              :message="errors.first('time', 'action')"
            >
              <ez-time-picker
                name="time"
                v-model="form.time"
                v-validate="'required'"
                data-vv-scope="action"
                :data-vv-as="$t('builder.actions.time.label')"
                :show-seconds="true"
              />
            </ez-form-item>
          </ez-col>
        </ez-row>
      </ez-form-group>

      <ez-form-group v-if="withOffer">
        <ez-form-item
          :label="$t('offer.label')"
          :data-vv-as="$t('offer.label')"
          :type="errors.has('offer-id', 'action') ? 'error' : null"
          :message="errors.first('offer-id', 'action')"
        >
          <ezf-select-webinar-offer
            v-model="form['offer-id']"
            v-validate="'required'"
            data-vv-scope="action"
            name="offer-id"
            :webinar-id="funnel.webinarId"
          />
        </ez-form-item>
      </ez-form-group>

      <ez-form-group>
        <ez-form-item
          :label="$t('builder.actions.action.label')"
          :type="errors.has('action-type', 'action') ? 'error' : null"
          :message="errors.first('action-type', 'action')"
        >
          <ez-select
            name="action-type"
            data-test="action-type"
            v-model="form['action-type']"
            v-validate="'required'"
            data-vv-scope="action"
            :data-vv-as="$t('builder.actions.action.label')"
            :placeholder="$t('builder.actions.action.placeholder')"
            :options="actionTypes"
            track-by="value"
            item-label="title"
            :allow-empty="false"
            :searchable="false"
            is-single
          />
        </ez-form-item>
      </ez-form-group>

      <ez-form-group v-if="withAutoresponder">
        <ez-form-item
          :label="$t('autoresponder.label')"
          :data-vv-as="$t('autoresponder.label')"
          :type="errors.has('account-id', 'action') ? 'error' : null"
          :message="errors.first('account-id', 'action')"
          :key="autoresponderType"
        >
          <ezf-select-autoresponder
            v-model="form['account-id']"
            v-validate="'required'"
            data-vv-scope="action"
            name="account-id"
            :type="autoresponderType"
          />
        </ez-form-item>
      </ez-form-group>

      <ez-form-group v-if="withMailList || withDoiList">
        <ez-form-item
          :label="withMailList ? $t('maillist.label') : $t('doiprocess.label')"
          :type="errors.has('list-id', 'action') ? 'error' : null"
          :message="errors.first('list-id', 'action') || klickTippDoiTooltip"
          :key="`${autoresponderType}:${form['account-id']}`"
        >
          <ezf-select-maillist
            v-model="form['list-id']"
            v-validate="'required'"
            data-vv-scope="action"
            :fail-i18n-key="
              withMailList ? 'maillist.failToLoad' : 'doiprocess.failToLoad'
            "
            :data-vv-as="
              withMailList ? $t('maillist.label') : $t('doiprocess.label')
            "
            name="list-id"
            :account-id="form['account-id']"
          />
        </ez-form-item>
      </ez-form-group>
      <ez-form-group v-if="withTagsToAdd">
        <ez-form-item :label="$t('tags.addLabel')">
          <div class="action-form__tags">
            <ez-select
              data-test="action-add-tag"
              v-model="form.tagsToAdd"
              :disabled="loading !== false"
              :placeholder="$t('tags.addPlaceholder')"
              :options="existingTags"
              :taggable="true"
              :multiple="true"
              item-label="name"
              track-by="name"
              @tag="addNewTagToAdd"
              @select="selectTagToAdd"
            />
          </div>
        </ez-form-item>
      </ez-form-group>
      <ez-form-group v-if="withTagsToDelete">
        <ez-form-item :label="$t('tags.removeLabel')">
          <div class="action-form__tags">
            <ez-select
              v-model="form.tagsToDelete"
              data-test="action-delete-tag"
              :disabled="loading !== false"
              :placeholder="$t('tags.removePlaceholder')"
              :options="existingTags"
              :taggable="true"
              :multiple="true"
              item-label="name"
              track-by="name"
              @select="selectTagToDelete"
            />
          </div>
        </ez-form-item>
      </ez-form-group>
      <ez-form-group v-if="withDoiSwitch">
        <div class="panel-collapse">
          <div class="panel-collapse__content">
            <ez-row align="center">
              <ez-col>
                <div class="panel-collapse__info">
                  {{ $t("builder.actions.DOI_switch.label") }}
                </div>
              </ez-col>
              <ez-col size="7">
                <div class="panel-collapse__switch">
                  <ez-switch
                    v-model="form['is-doi']"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                  ></ez-switch>
                </div>
              </ez-col>
            </ez-row>
          </div>
        </div>
      </ez-form-group>

      <ez-form-group v-if="withCycleDay">
        <ez-row>
          <ez-col size="4">
            <ez-form-item
              :label="$t('builder.actions.cycleDay.label')"
              :type="errors.has('day-of-cycle', 'action') ? 'error' : null"
              :message="errors.first('day-of-cycle', 'action')"
            >
              <ez-input
                type="number"
                v-model="form['day-of-cycle']"
                name="day-of-cycle"
                min="0"
                v-validate="'required'"
                data-vv-scope="action"
                :data-vv-as="$t('builder.actions.cycleDay.label')"
              />
            </ez-form-item>
          </ez-col>
        </ez-row>
      </ez-form-group>

      <template v-if="isEmailActionType">
        <ez-form-item
          :label="$t('builder.notification.label.subject')"
          :type="errors.has('subject', 'action') ? 'error' : null"
          :message="errors.first('subject', 'action')"
        >
          <ez-input
            name="subject"
            v-model="form.subject"
            v-validate="'min:2|required'"
            data-vv-scope="action"
            data-vv-as="Subject"
          />
        </ez-form-item>
        <ez-form-item
          :label="$t('builder.notification.label.cc')"
          :type="errors.has('cc', 'action') ? 'error' : null"
          :message="errors.first('cc', 'action')"
        >
          <ezf-input-multiple-emails
            v-model="form.cc"
            name="cc"
            data-vv-as="CC"
            data-vv-scope="action"
          />
        </ez-form-item>
        <ez-form-item
          :label="$t('builder.notification.label.bc')"
          :type="errors.has('bc', 'action') ? 'error' : null"
          :message="errors.first('bc', 'action')"
        >
          <ezf-input-multiple-emails
            v-model="form.bc"
            name="bc"
            data-vv-as="BC"
            data-vv-scope="action"
          />
        </ez-form-item>
        <ez-form-item
          :label="$t('builder.notification.label.template')"
          :type="errors.has('template-id', 'action') ? 'error' : null"
          :message="errors.first('template-id', 'action')"
        >
          <div v-if="isEdit" class="add-email__change-template" :gutter="16">
            <a
              :href="editTemplateHref"
              target="_blank"
              style="text-decoration: none"
            >
              <ez-button>
                {{ $t("builder.buttons.editTemplate") }}
              </ez-button>
            </a>
          </div>
          <ezf-email-template-select
            v-else
            name="template-id"
            :type="emailType"
            v-model="form['template-id']"
            v-validate="'required'"
            data-vv-scope="action"
            data-vv-as="Template"
          />
        </ez-form-item>
      </template>
    </ez-dialog-body>

    <ez-dialog-footer>
      <ez-row justify="between">
        <ez-button-group justify="start">
          <ez-button
            v-if="isEdit"
            type="danger"
            @click="remove"
            :disabled="loading !== false"
            :preloader="loading === 'remove'"
          >
            {{ $t("builder.buttons.delete") }}
          </ez-button>
        </ez-button-group>
        <ez-button-group justify="end">
          <ez-button
            type="secondary"
            @click="close()"
            :disabled="loading !== false"
          >
            {{ $t("builder.buttons.discard") }}
          </ez-button>
          <ez-button
            type="success"
            data-test="action-submit"
            @click="submit"
            :disabled="loading !== false"
            :preloader="loading === 'submit'"
          >
            {{
              isEdit
                ? $t("builder.buttons.saveChanges")
                : $t("builder.buttons.create")
            }}
          </ez-button>
        </ez-button-group>
      </ez-row>
    </ez-dialog-footer>
  </ez-form>
</template>
