<script>
import { mapGetters } from 'vuex';
import webinarsManager from '@/services/webinarsManager';

export default {
  name: 'EzfSelectWebinarOffer',
  props: ['value', 'type', 'message', 'webinarId', 'failI18nKey'],
  computed: {
    ...mapGetters(['currentUserId']),
    selected: {
      set(value) {
        this.$emit('input', value && value.id);
      },
      get() {
        return this.options.find((row) => row.id === this.value);
      },
    },
    placeholder() {
      if (this.loading) {
        return this.$t('Loading…');
      } if (this.options.length === 0) {
        return this.$t('offer.empty');
      }
      return this.$t('offer.placeholder');
    },
  },
  data() {
    return {
      loading: true,
      options: [],
    };
  },
  methods: {
    async load(webinarId) {
      this.loading = true;
      this.options = [];
      try {
        const result = await webinarsManager.getOffers(webinarId);
        if (result) {
          const options = result || [];
          if (this.value && !options.some((r) => r.id === this.value)) {
            this.$emit('input', null);
          }
          this.options = options;
        // if (options.length === 1) {
        //   this.$emit('input', options[0].id);
        // }
        } else if (this.value) {
          this.$emit('input', null);
        }
      } catch (e) {
        this.$notify({
          data: {
            type: 'error',
            content: this.$t(this.failI18nKey, { message: e.message }),
          },
        });
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    webinarId: {
      handler(webinarId) {
        this.load(webinarId);
      },
      immediate: true,
    },
  },
};
</script>

<template>
  <ez-select
    class="no-dnd"
    v-model="selected"
    :readonly="loading"
    :placeholder="placeholder"
    :options="options"
    track-by="id"
    item-label="title"
    :allow-empty="false"
    :searchable="false"
  />
</template>
