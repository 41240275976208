<script>
import { mapGetters } from 'vuex';
import usersManager from '@/services/usersManager';

export default {
  name: 'EzfSelectMaillist',
  props: ['value', 'type', 'message', 'accountId', 'failI18nKey'],
  computed: {
    ...mapGetters(['currentUserId']),
    selected: {
      set(value) {
        this.$emit('input', value && value.id);
      },
      get() {
        return this.options.find((row) => row.id === this.value);
      },
    },
  },
  data() {
    return {
      loading: true,
      options: [],
    };
  },
  methods: {
    async load(accountId) {
      this.loading = true;
      this.options = [];
      try {
        const result = await usersManager.getMailList(accountId);
        if (result.data.lists) {
          const options = result.data.lists.map((r) => ({
            id: String(r.id),
            name: r.name || r.value,
          }));
          if (this.value && !options.some((r) => r.id === String(this.value))) {
            this.$emit('input', null);
          }
          this.options = options;
          // if (options.length === 1) {
          //   this.$emit('input', options[0].id);
          // }
        } else if (this.value) {
          this.$emit('input', null);
        }
      } catch (e) {
        this.$notify({
          data: {
            type: 'error',
            content: this.$t(this.failI18nKey, { message: e.message }),
          },
        });
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    accountId: {
      handler(accountId) {
        this.load(accountId);
      },
      immediate: true,
    },
  },
};
</script>

<template>
  <ez-select
    class="no-dnd"
    v-model="selected"
    :readonly="loading"
    :placeholder="loading ? $t('Loading…') : $t('maillist.placeholder')"
    :options="options"
    track-by="id"
    item-label="name"
    :allow-empty="false"
    :searchable="true"
  />
</template>
