<script>
import { mapGetters } from 'vuex';

import usersManager from '@/services/usersManager';

export default {
  name: 'EzfSelectAutoresponder',
  props: ['value', 'type', 'message'],
  computed: {
    ...mapGetters(['currentUserId']),
    selected: {
      set(value) {
        this.$emit('input', value && value.id);
      },
      get() {
        return this.options.find((row) => row.id === this.value);
      },
    },
    placeholder() {
      if (this.loading) {
        return this.$t('Loading…');
      } if (this.options.length === 0) {
        return this.$t('autoresponder.empty');
      }
      return this.$t('autoresponder.placeholder');
    },
  },
  data() {
    return {
      loading: true,
      options: [],
    };
  },
  methods: {
    async load(type) {
      this.loading = true;
      this.options = [];
      try {
        const result = await usersManager.getAutoresponders(this.currentUserId, type);
        const options = result.data.map((row) => ({
          id: row.id,
          name: row.attributes.name,
        }));
        this.options = options;
        // if (options.length === 1) {
        //   this.$emit('input', this.options[0].id);
        // }
      } catch (e) {
        this.$notify({
          data: {
            type: 'error',
            content: this.$t('autoresponder.failToLoad', { message: e.message }),
          },
        });
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    type: {
      handler(type) {
        this.load(type);
      },
      immediate: true,
    },
  },
};
</script>

<template>
  <ez-select
    class="no-dnd"
    v-model="selected"
    :readonly="loading"
    :placeholder="placeholder"
    :options="options"
    track-by="id"
    item-label="name"
    :allow-empty="false"
    :searchable="false"
  />
</template>
