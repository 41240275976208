import ACTION_TYPES from '@/enums/actionTypes';

export const MAILLISTS = Symbol('maillist');
export const DOI_LIST = Symbol('DOI process');
export const TAGS_TO_ADD = Symbol('tags-to-add');
export const TAGS_TO_DELETE = Symbol('tags-to-delete');
export const DOI_SWITCH = Symbol('doi');
export const CYCLE_DAY = Symbol('cycle');

const map = new Map([
  [ACTION_TYPES.CONTACTS, new Set([TAGS_TO_ADD, TAGS_TO_DELETE])],
  [ACTION_TYPES.MAIL_CHIMP, new Set([MAILLISTS, DOI_SWITCH])],
  [ACTION_TYPES.GET_RESPONSE, new Set([MAILLISTS, TAGS_TO_ADD, TAGS_TO_DELETE, CYCLE_DAY])],
  [ACTION_TYPES.QUENTIN, new Set([TAGS_TO_ADD, DOI_SWITCH])],
  [ACTION_TYPES.KLICK_TIPP, new Set([DOI_LIST, TAGS_TO_ADD, TAGS_TO_DELETE])],
  [ACTION_TYPES.ACTIVE_CAMPAGIN, new Set([MAILLISTS, TAGS_TO_ADD, TAGS_TO_DELETE])],
  [ACTION_TYPES.FOUR_LEADS, new Set([TAGS_TO_ADD, TAGS_TO_DELETE])],
]);

const emptySet = new Set();

export default function getProps(actionType) {
  if (actionType && map.has(actionType)) {
    return map.get(actionType);
  }
  return emptySet;
}
