import getApiAxios from './lib/apiAxios';

/**
 * Class for manupulate actions with ez-builder API.
 * @module ActionsRepository
 */
class ActionsRepository {
  /**
   * @param {object} axiosInstance axios instance(can be undefined)
   * @param {string} apiUrl url to ez-builder API
   */
  constructor(axiosInstance, apiUrl) {
    this.axios = getApiAxios(axiosInstance, { baseURL: `${apiUrl}/v1/` });
  }

  async createAction(attributes, { userId, stepId }) {
    const { data } = await this.axios.post('actions', {
      data: {
        attributes,
        relationships: {
          user: {
            data: {
              type: 'user',
              id: userId,
            },
          },
          step: {
            data: {
              type: 'step',
              id: stepId,
            },
          },
        },
        type: 'actions',
      },
    });
    return data;
  }

  async updateAction(id, attributes) {
    const { data } = await this.axios.patch(`actions/${id}`, {
      data: {
        attributes,
        type: 'actions',
      },
    });
    return data;
  }

  async bulkCreateTagsToAdd(id, tags) {
    const { data } = await this.axios.post(`actions/${id}/bulk-create-tags-to-add`, {
      data: {
        tags,
      },
    });
    return data;
  }

  async bulkCreateTagsToDelete(id, tags) {
    const { data } = await this.axios.post(`actions/${id}/bulk-create-tags-to-delete`, {
      data: {
        tags,
      },
    });
    return data;
  }

  async removeAction(id) {
    const { data } = await this.axios.delete(`actions/${id}`);
    return data;
  }

  async getList({ query }) {
    const params = {};
    for (const [key, value] of Object.entries(query)) {
      params[`filter[actions][and][${key}][eq]`] = value;
    }
    const response = await this.axios.get('actions', {
      params,
      // include: 'actions-tags-add,actions-tags-delete',
    });
    return response.data;
  }

  async getTagsToAdd(id) {
    const { data } = await this.axios.get(`actions/${id}/actions-tags-add`);
    return data;
  }

  async getTagsToDelete(id) {
    const { data } = await this.axios.get(`actions/${id}/actions-tags-delete`);
    return data;
  }
}

export default ActionsRepository;
